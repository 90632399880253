import maxBy from 'lodash/maxBy';
import minBy from 'lodash/minBy';

import { MEDIA_CLOUDINARY_PARAMETERS, MEDIA_URL } from '../../utils/constants';

import type { TProductES } from '@vitafy/common-schemas';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dataLayer: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    gtag?: any;
  }
}

const canUseDOM = () => !!(typeof window !== 'undefined' && window.document && window.document.createElement);

type GAEventOptions = {
  category?: string;
  action?: string;
  label?: string;
  additionalTrackingParameters?: object;
};

function sendGAEvent({
  category = '',
  action = '',
  label = '',
  additionalTrackingParameters
}: GAEventOptions) {
  if (window && window.dataLayer) {
    window.dataLayer.push({
      event: 'ga-event',
      'event-action': action,
      'event-cat': category,
      'event-label': label,
      ...additionalTrackingParameters
    });
    return true;
  }

  return false;
}

type MediaUrlOptionsType = {
  additionalTransformations?: string;
  height?: number;
  isSvg?: boolean;
  width?: number;
};

export const mediaUrlParameters = ({
  additionalTransformations = '',
  height,
  isSvg = false,
  width,
  wpContent = false
}: MediaUrlOptionsType & { wpContent?: boolean } = {}) =>
  `vitafy/${
    wpContent ? 'image/fetch/' : 'image/upload/'
  }${additionalTransformations}${isSvg ? '' : MEDIA_CLOUDINARY_PARAMETERS}${width ? `,w_${width}` : ''}${
    height ? `,h_${height}` : ''
  }`;

export const buildMediaUrl = (imagePath: string, options: MediaUrlOptionsType = {}) => {
  if (imagePath.startsWith(MEDIA_URL)) {
    return imagePath;
  }
  return `${MEDIA_URL}/${mediaUrlParameters({
    ...options,
    isSvg: imagePath.endsWith('.svg'),
    wpContent: imagePath?.includes('wp-content')
  })}/${imagePath}`;
};

export const getLoaderWithAdditionalTransformations = (additionalTransformations: string) => {
  const additionalTransformationsWithSlash = additionalTransformations.endsWith('/')
    ? additionalTransformations
    : `${additionalTransformations}/`;
  return ({ src, width, height }: { src: string; width?: number; height?: number }) =>
    buildMediaUrl(src, { width, height, additionalTransformations: additionalTransformationsWithSlash });
};

type BuildSrcSetOptions = {
  heightRatio?: number;
};

export const buildSrcSet = (
  imagePath: string,
  widthsArray: number[],
  { heightRatio = undefined }: BuildSrcSetOptions = {}
) =>
  widthsArray
    .map(
      (width) =>
        `${buildMediaUrl(imagePath, {
          width,
          height: heightRatio ? width * heightRatio : undefined
        })} ${width}w`
    )
    .join(', ');

export { canUseDOM, sendGAEvent };

export const formatPrice = (
  price: string | number,
  options: { locale: string; currency: string; maximumFractionDigits?: number }
): string => {
  if (Number.isNaN(Number(price))) {
    return price.toString();
  }

  const { currency = 'EUR', locale = 'de-DE', maximumFractionDigits = 2 } = options;
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currency,
    maximumFractionDigits
  }).format(Number(price));
};

export type Prices = {
  base: string;
  discount?: number | null;
  isSpecialPrice: boolean;
  priceOld?: string | null;
  price: string;
  priceValue: number;
};
type formatPricesSignature = (v: TProductES['prices']) => Prices;

export const formatPrices: formatPricesSignature = ({ regular, msrp, special, base }) => {
  // ignore data quality issue by removing msrp if it is higher than regular price
  const msrpNormalized = {
    ...msrp,
    value: Math.max(msrp?.value ?? 0, regular.value ?? 0)
  };

  const prices = [regular, msrpNormalized, special];
  // find min and max price and mark them as not undefined because we know that there is at least one price
  const priceMin = minBy(
    prices.filter(({ value }) => typeof value === 'number' && value !== 0),
    'value'
  )!;
  const priceMax = maxBy(prices, 'value')!;

  return {
    base: base ?? '',
    discount: priceMin.value
      ? Math.round((((priceMax.value ?? 0) - priceMin.value) / (priceMax.value ?? 1)) * 100)
      : null,
    isSpecialPrice: (msrp.value ?? 0) > (regular.value ?? 0) || !!special.value,
    priceOld: (priceMax.value ?? 0) > (priceMin.value ?? 0) ? priceMax.formatted ?? null : null,
    price: priceMin.formatted ?? '0',
    priceValue: priceMin.value ?? 0
  };
};

export const findCategory = (categoryId, categoryTree) => {
  if (categoryTree === undefined || categoryTree.length === 0) {
    return undefined;
  }

  const firstLevelLookupResult = categoryTree.find((categoryItem) => categoryItem.id === categoryId);
  if (firstLevelLookupResult) {
    return firstLevelLookupResult;
  }

  return findCategory(
    categoryId,
    categoryTree.flatMap((categoryItem) => categoryItem.children || [])
  );
};

export const makeUrlAbsolute = (url: string): string =>
  typeof url !== 'string' ? url : url[0] === '/' ? url : `/${url}`;

// check if an uri component needs to be encoded
export const isUriComponentEncoded = (uriComponent: string): boolean => {
  try {
    return uriComponent !== decodeURIComponent(uriComponent);
  } catch (e) {
    return false;
  }
};

// encode uri component if it is not already encoded
export const encodeUriComponentIfNeeded = (uriComponent: string): string => {
  return isUriComponentEncoded(uriComponent) ? uriComponent : encodeURIComponent(uriComponent);
};

export const insertNewlines = (string: string) =>
  string?.split('//').map((text) => <div key={text}>{text}</div>);

export const filterUndefinedProps = (obj) => {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    if (value !== undefined) {
      acc[key] = value;
    }
    return acc;
  }, {});
};
